// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use 'src/assets/scss/resets' as resets;
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`

@include mat.core();
$inter-level: mat.define-typography-level(
  $font-family: Inter,
  $font-weight: 400,
  $font-size: 1rem,
  $line-height: 1,
  $letter-spacing: normal
);
$inter-typography: mat.define-typography-config(
  $headline-1: mat.define-typography-level(inherit, inherit, inherit, $letter-spacing: -0.05em),
  $headline-2: mat.define-typography-level(inherit, inherit, inherit, $letter-spacing: -0.02em),
  $headline-3: mat.define-typography-level(inherit, inherit, inherit, $letter-spacing: -0.005em),
  $headline-4: mat.define-typography-level(inherit, inherit, inherit),
  //h1
  $headline-5: mat.define-typography-level(inherit, inherit, inherit),
  //h2
  $headline-6: mat.define-typography-level(inherit, inherit, inherit),
  //h3
  $subtitle-1: mat.define-typography-level(inherit, inherit, inherit),
  //h4
  $subtitle-2: mat.define-typography-level(inherit, inherit, inherit),
  //Base body mat-error dialog inputs
  $body-1:
    mat.define-typography-level(
      $font-size: 16px,
      $line-height: 20px,
      $font-weight: 400,
      $font-family: Inter,
      $letter-spacing: 0.1px
    ),
  //Secondary body table
  $body-2:
    mat.define-typography-level(
      $font-size: 14px,
      $line-height: 17px,
      $font-weight: 500,
      $font-family: Inter,
      $letter-spacing: -2%
    ),
  //Smaller body and hint text, paginator.
  $caption:
    mat.define-typography-level(
      $font-size: 12px,
      $line-height: inherit,
      $font-weight: 400,
      $font-family: Inter,
      $letter-spacing: normal
    ),
  //Buttons and anchors
  $button:
    mat.define-typography-level(
      $font-size: 16px,
      $line-height: 20px,
      $font-weight: 700,
      $font-family: Inter,
      $letter-spacing: normal
    )
);
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$market-place-primary: mat.define-palette(mat.$blue-gray-palette, 900);
$market-place-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$market-place-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$market-place-theme: mat.define-light-theme(
  (
    color: (
      primary: $market-place-primary,
      accent: $market-place-accent,
      warn: $market-place-warn
    ),
    typography: $inter-typography,
    density: 0
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($market-place-theme);

/* You can add global styles to this file, and also import other style files */

@import 'src/json-edit.scss';
@import 'src/assets/scss/partials/fix-material.scss';
