@import 'fonts';

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -moz-text-rendering: optimizeLegibility;
  -ms-text-rendering: optimizeLegibility;
  -o-text-rendering: optimizeLegibility;
  -webkit-text-rendering: optimizeLegibility;
  text-rendering: optimizeLegibility;

  &:before,
  &:after {
    box-sizing: border-box;
  }

  // Remove focus outline
  &:focus {
    outline: none;
  }
}

html,
body {
  height: 100%;
}

body {
  font-family: $family-inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: 400;
  margin: 0;
}

ul:not(.with-bullets),
ol:not(.with-bullets) {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.with-bullets,
ol.with-bullets {
  line-height: initial;
  margin: 0;
}

b {
  font-weight: 700;
}

i {
  font-style: italic;
}

img {
  border: 0;
  height: auto;
  max-width: 100%;
  vertical-align: baseline;
}

a {
  cursor: pointer;
  text-decoration: none;
}

.hidden {
  display: none;
}

// Autofill inputs remove background
input:-webkit-autofill {
  -ms-background-clip: text;
  -webkit-background-clip: text;
  background-clip: text;
}

input[data-autocompleted] {
  background-color: transparent !important;
}
