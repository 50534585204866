//Botones
.marketplace-default {
  .mat-mdc-button,
  .mat-mdc-fab,
  .mat-mdc-raised-button,
  .mat-mdc-mini-fab,
  .mat-mdc-icon-button,
  .mat-mdc-unelevated-button,
  .mat-mdc-raised-button,
  .mat-mdc-outlined-button {
    min-width: 40px;
    padding: 10px 20px;
    border-radius: 6px;
    height: 40px;
    width: inherit;

    &.mat-accent:not(.mat-button-disabled) {
      color: $color-white;
      background-color: $color-coral;
    }

    &.mat-accent-inverted:not(.mat-button-disabled) {
      color: $color-coral;
      background-color: $color-white;
    }

    &.mat-dark-blue:not(.mat-button-disabled),
    &.mat-primary:not(.mat-button-disabled) {
      color: $color-white;
      background-color: $color-dark-blue-1;
    }

    &.mat-dark-blue-inverted:not(.mat-button-disabled) {
      color: $color-dark-blue-1;
      background-color: $color-white;
    }

    &.mat-light-blue:not(.mat-button-disabled) {
      color: $color-dark-blue-1;
      background-color: $color-light-blue;
    }

    &.mat-light-grey-2:not(.mat-button-disabled) {
      background-color: $color-light-grey-2;
      color: $color-dark-grey-1;
    }

    &.mat-orange:not(.mat-button-disabled) {
      color: $color-white;
      background-color: $color-orange;
    }

    &.mat-white:not(.mat-button-disabled) {
      color: $color-dark-blue;
      background-color: $color-white;
    }

    &.mat-transparent:not(.mat-button-disabled):not(.mat-mdc-outlined-button) {
      color: $color-dark-grey;
      background-color: $color-transparent;
    }

    &.mat-light-purple {
      color: $color-dark-grey-1 !important;
      background-color: $color-light-purple !important;
    }

    &.mat-purple {
      color: $color-white !important;
      background-color: $color-purple !important;
    }

    &.mat-purple-inverted {
      color: $color-purple !important;
      background-color: $color-transparent !important;
    }
  }

  .mat-mdc-outlined-button {
    &.mat-transparent {
      color: $color-dark-blue-1;
      background-color: $color-transparent;
      border: 1px solid $color-light-purple-1;
    }
  }

  .mat-mdc-raised-button {
    @include elevation-option-active;
  }

  .mat-mdc-fab:not(.mdc-fab--extended) {
    width: 40px;
    height: 40px;
    border-radius: 6px !important;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    box-shadow: none;

    & .mat-icon {
      color: inherit;
      overflow: visible;
    }

    & .mat-mdc-button-persistent-ripple {
      border-radius: 6px !important;
    }
  }

  .mat-mdc-mini-fab:not(.mdc-fab--extended) {
    width: 40px;
    height: 40px;
    margin: 0 5px;
    border-radius: 50%;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    @include elevation-option-active;
    @include media-breakpoint('mobile') {
      width: 56px;
      height: 56px;
      line-height: 56px;

      & .mat-mdc-button-persistent-ripple {
        border-radius: 50%;
      }
    }
  }

  .mat-mdc-icon-button {
    width: 40px;
    height: 40px;
    color: $color-black;
    padding: 5px;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    & .mat-mdc-button-persistent-ripple:before {
      background-color: transparent;
    }
  }

  .mat-mdc-button[disabled],
  .mat-mdc-fab[disabled],
  .mat-mdc-mini-fab[disabled],
  .mat-mdc-unelevated-button[disabled],
  .mat-mdc-raised-button[disabled],
  .mat-mdc-outlined-button[disabled] {
    cursor: default;
    pointer-events: none;
    background-color: $color-light-grey-0 !important;
    color: $color-white !important;
  }

  .mat-mdc-icon-button[disabled] {
    cursor: default;
    pointer-events: none;
    background-color: $color-transparent !important;
    color: $color-light-grey-0 !important;
  }

  .mat-mdc-button .mat-mdc-button-touch-target,
  .mat-mdc-unelevated-button .mat-mdc-button-touch-target,
  .mat-mdc-raised-button .mat-mdc-button-touch-target,
  .mat-mdc-outlined-button .mat-mdc-button-touch-target {
    height: inherit !important;
  }
}
