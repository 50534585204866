.marketplace-default {
  button,
  input[type='email'],
  input[type='tel'],
  input[type='text'],
  input[type='password'],
  input[type='image'],
  input[type='submit'],
  input[type='button'],
  input[type='search'],
  textarea {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    outline: none;
  }

  // Autofill inputs remove background
  input:-webkit-autofill {
    background-clip: text;
    -ms-background-clip: text;
    -webkit-background-clip: text;
  }

  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type='color' i]::-webkit-color-swatch-wrapper {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    -webkit-user-modify: read-only !important;
    padding: 0;
    border-radius: 0;
    overflow: hidden;
  }

  input[type='color'] {
    caret-color: transparent !important;
    overflow: hidden;
  }

  input[type='color' i]::-webkit-color-swatch {
    color-scheme: light only;
    background-color: rgb(0, 0, 0);
    min-width: 0;
    -webkit-user-modify: read-only !important;
    border-width: 0;
    border-style: solid;
    border-color: transparent !important;
    border-image: initial;
    flex: 1 1 0;
  }

  input[data-autocompleted] {
    background-color: transparent !important;
  }

  label {
    font-family: $family-inter;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: inherit;
    letter-spacing: 0.5px;
    cursor: pointer;

    & > span {
      font-family: inherit;
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
      color: inherit;
      letter-spacing: inherit;
      cursor: inherit;
    }
  }

  //Material overrides

  .mat-mdc-form-field {
    width: 100%;

    & .mat-mdc-text-field-wrapper {
      background-color: $color-white;
      color: $color-black;
      border-radius: 4px;

      & .mat-mdc-form-field-flex {
        align-items: center;
        height: 100%;

        & .mat-mdc-form-field-infix {
          min-height: 40px;
          padding: 5px 0;
          display: inherit;

          & input:-webkit-autofill {
            background-clip: text;
            -ms-background-clip: text;
            -webkit-background-clip: text;
            transform: translateX(5.5%) translateY(0) scale(1.1);
            width: 94%;
          }

          & input::placeholder {
            font-family: $family-inter;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            letter-spacing: 0.5px;
            color: $color-grey-50;
            transform: translateX(0) translateY(0) scale(1);
          }

          & .mat-mdc-input-element {
            font-family: Inter, sans-serif;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            letter-spacing: 0.5px;

            &.cdk-text-field-autofilled {
              color: $color-dark-grey-1;
              caret-color: $color-dark-grey;
              vertical-align: middle;
              transform: translateX(0) translateY(0) scale(1);
              width: 100%;
              max-width: inherit;
              margin: 0;
              flex: 0 0 100%;
              overflow: hidden;

              &.ng-pristine {
                transform: translateX(13%) translateY(-5%) scale(1.25);
                width: 80%;
              }

              &.ng-untouched {
                transform: translateX(13%) translateY(-5%) scale(1.25);
                width: 80%;
              }

              &.ng-touched {
                transform: translateX(0.5%) translateY(0) scale(1);
                width: 100%;
              }
            }
          }

          & .mat-mdc-select {
            font-family: Inter, sans-serif;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            letter-spacing: 0.5px;

            & .mat-mdc-select-trigger {
              & .mat-mdc-select-value {
                & .mat-mdc-select-value-text {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: inline-block;
                  vertical-align: middle;
                  width: 100%;

                  & .mat-mdc-select-min-line {
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;
                    vertical-align: middle;
                  }
                }

                & .mat-mdc-select-min-line {
                  max-width: 100%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: inline-block;
                  vertical-align: middle;
                }
              }
            }
          }
        }

        & .mat-mdc-form-field-icon-prefix,
        & .mat-mdc-form-field-icon-suffix {
          & > .mat-icon {
            font-size: 24px;
            line-height: 24px;
            width: 24px;
            height: 24px;
            color: $color-grey-50;
            padding: 5px 16px;
          }
        }
      }
    }

    &.mat-focused {
      & .mat-mdc-text-field-wrapper {
        & .mat-mdc-form-field-flex {
          & .mdc-notched-outline {
            .mdc-notched-outline__notch {
              border-top: 2px solid;
              padding: 0;
              width: calc(
                103px * var(--mat-mdc-form-field-floating-label-scale, 0.75) + 9px
              ) !important;
            }

            .mdc-floating-label {
              font-family: Inter, sans-serif;
              font-size: 12px;
              line-height: 24px;
              font-weight: 400;
              letter-spacing: 0.5px;
            }

            .mdc-floating-label--float-above {
              font-family: Inter, sans-serif;
              font-size: 16px;
              line-height: 24px;
              font-weight: 400;
              letter-spacing: 0.5px;
              transform: translateX(2px) translateY(-18px) scale(0.75);
            }
          }

          & .mat-mdc-form-field-infix {
            & .mat-mdc-select {
              & .mat-mdc-select-trigger {
                & .mat-mdc-select-value {
                  & .mat-mdc-select-value-text {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;

                    & .mat-mdc-select-min-line {
                      font-family: $family-inter;
                      font-size: 16px;
                      line-height: 24px;
                      max-width: 100%;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: inline-block;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .mat-mdc-form-field-subscript-wrapper {
      .mat-mdc-form-field-hint-wrapper {
        .mat-mdc-form-field-hint-spacer {
          /*
            font-family: 'Inter-Regular';
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.5px;
            color: $color-medium-grey;
            margin: 4.5px 0 0;
            display: inline-block;*/
        }
      }

      .mat-mdc-form-field-error {
        font-family: $family-inter;
        font-weight: 400;
        letter-spacing: 0.5px;
        font-size: 12px;
        line-height: 16px;
        color: $color-coral;
      }
    }

    //Select con label(header panel)

    &.select-header {
      .mat-mdc-text-field-wrapper {
        height: 40px;

        .mat-mdc-form-field-flex {
          .mdc-notched-outline {
            & .mdc-notched-outline__notch {
              width: auto !important;
              border-top: 1px solid rgba(0, 0, 0, 0.38);
            }

            & .mat-mdc-floating-label {
              font-family: Inter, sans-serif;
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
              top: 19px;
              color: rgba(0, 0, 0, 0.87);
            }

            .mdc-floating-label--float-above {
              transform: matrix(1, 0, 0, 1, 0, -14);
              color: rgba(0, 0, 0, 0.87);
            }
          }

          .mat-mdc-form-field-infix {
            .mat-mdc-select {
              & .mat-mdc-select-trigger {
                .mat-mdc-select-value-text {
                  font-family: Inter, sans-serif;
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 20px;

                  letter-spacing: 0.5px;
                  transform: translateX(0) translateY(14px) scale(1);
                  max-width: 125%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: inline-block;
                  vertical-align: initial;

                  & .mat-mdc-select-min-line {
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;
                    vertical-align: initial;
                  }
                }
              }
            }
          }

          &:hover {
            .mdc-notched-outline {
              & .mdc-notched-outline__notch {
                border-top: 1px solid rgba(0, 0, 0, 0.87);
              }
            }
          }
        }

        & + .mat-mdc-form-field-subscript-wrapper {
          display: none;

          &:before {
            content: '';
            display: none;
            height: 0;
          }
        }
      }

      &.mat-focused {
        .mat-mdc-text-field-wrapper {
          .mat-mdc-form-field-flex {
            .mdc-notched-outline {
              & .mdc-notched-outline__notch {
                width: auto !important;
                border-top: 2px solid rgba(0, 0, 0, 0.87);
              }

              & .mat-mdc-floating-label {
                font-family: Inter, sans-serif;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                top: 19px;
                color: rgba(0, 0, 0, 0.87);
              }

              .mdc-floating-label--float-above {
                transform: matrix(1, 0, 0, 1, 0, -14);
                color: rgba(0, 0, 0, 0.87);
              }
            }

            .mat-mdc-form-field-infix {
              .mat-mdc-select {
                & .mat-mdc-select-trigger {
                  .mat-mdc-select-value-text {
                    transform: translateX(-12%) translateY(11px) scale(0.75);
                    max-width: 125%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;

                    & .mat-mdc-select-min-line {
                      max-width: 100%;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: inline-block;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  //Desplegable con las opciones del select
  .mat-mdc-select-panel.mdc-menu-surface {
    background-color: $color-white;
    margin-top: 2px !important;
    margin-bottom: 2px !important;
    border: 2px solid $color-black;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
    max-height: calc(48px * 4) !important;

    //Opciones del resto de selects
    .mat-mdc-option {
      & .mdc-list-item__primary-text {
        font-family: Inter, sans-serif;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        letter-spacing: 0.5px;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        max-width: 100%;

        display: inline-block;
        white-space: nowrap;
      }

      &-active:not(.mat-mdc-option-multiple) {
      }

      &.mdc-list-item--selected {
        background: rgba(73, 69, 79, 0.08);
        pointer-events: none;
        color: gray;

        & .mdc-list-item__primary-text {
          font-weight: 500;
          color: inherit;
        }
      }

      &:hover {
        background: rgba(73, 69, 79, 0.08);
      }

      .mat-mdc-option-pseudo-checkbox {
        &.mat-pseudo-checkbox-minimal {
          margin-left: 0;
          width: 16px;
          height: 16px;

          &:after {
            color: currentColor;
            top: 0;
            margin: 0;
          }
        }
      }
    }

    //Opciones del select con label(header panel)

    &.select-header {
      .mat-mdc-option {
        & .mdc-list-item__primary-text {
          font-family: Inter, sans-serif;
          font-size: 12px;
          line-height: 24px;
          font-weight: 400;
          letter-spacing: 0.5px;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 100%;
          display: inline-block;
          white-space: nowrap;
        }

        &.mdc-list-item--selected {
          background: rgba(73, 69, 79, 0.08);
          pointer-events: none;
          color: gray;

          & .mdc-list-item__primary-text {
            font-family: $family-inter;
            font-size: 12px;
            line-height: 24px;
            font-weight: 500;
            letter-spacing: 0.5px;
            color: inherit;
            background-color: inherit;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100%;
            display: inline-block;
            white-space: nowrap;
          }
        }
      }
    }

    .mat-mdc-option-multiple {
    }
  }

  //Checkbox
  .mat-mdc-checkbox {
    margin-right: 20px;

    &.mat-white {
      --mdc-checkbox-selected-checkmark-color: $color-white;
      --mdc-checkbox-selected-focus-icon-color: $color-transparent;
      --mdc-checkbox-selected-hover-icon-color: $color-transparent;
      --mdc-checkbox-selected-icon-color: $color-transparent;
      --mdc-checkbox-selected-pressed-icon-color: $color-transparent;
      --mdc-checkbox-unselected-focus-icon-color: $color-white;
      --mdc-checkbox-unselected-hover-icon-color: $color-white;
      --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
      --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
      --mdc-checkbox-unselected-icon-color: $color-white;
      --mdc-checkbox-unselected-pressed-icon-color: $color-white;

      &
        .mdc-checkbox
        .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
          [data-indeterminate='true']
        )
        ~ .mdc-checkbox__background {
        background-color: $color-transparent;
        border-color: $color-white;

        & .mdc-checkbox__checkmark {
          color: $color-white;
        }
      }

      &
        .mdc-checkbox
        .mdc-checkbox__native-control:enabled:not(:indeterminate):not([data-indeterminate='true'])
        ~ .mdc-checkbox__background {
        background-color: $color-transparent;
        border-color: $color-white;

        & .mdc-checkbox__checkmark {
          color: $color-white;
        }
      }

      label {
        color: $color-white;
      }
    }
    &.mat-primary,
    &.mat-purple {
      --mdc-checkbox-selected-checkmark-color: #fff;
      --mdc-checkbox-selected-focus-icon-color: #b428be;
      --mdc-checkbox-selected-hover-icon-color: #b428be;
      --mdc-checkbox-selected-icon-color: #b428be;
      --mdc-checkbox-selected-pressed-icon-color: #b428be;
      --mdc-checkbox-unselected-focus-icon-color: #212121;
      --mdc-checkbox-unselected-hover-icon-color: #212121;
      --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.18);
      --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.18);
      --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
      --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
    }
    &.mat-dark-blue {
      --mdc-checkbox-selected-checkmark-color: #fff;
      --mdc-checkbox-selected-focus-icon-color: #00344f;
      --mdc-checkbox-selected-hover-icon-color: #00344f;
      --mdc-checkbox-selected-icon-color: #00344f;
      --mdc-checkbox-selected-pressed-icon-color: #00344f;
      --mdc-checkbox-unselected-focus-icon-color: #212121;
      --mdc-checkbox-unselected-hover-icon-color: #212121;
      --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.18);
      --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.18);
      --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
      --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
    }

    label {
      letter-spacing: 0;

      @include media-breakpoint('tablet-l') {
        letter-spacing: -0.5px;
      }
    }
    &-disabled {
      label {
        color: rgba(0, 0, 0, 0.38);
      }
    }
  }

  //Radio
  .mat-mdc-radio-button {
    margin-right: 20px;

    &.mat-primary,
    &.mat-purple {
      --mdc-radio-selected-focus-icon-color: #b428be;
      --mdc-radio-selected-hover-icon-color: #b428be;
      --mdc-radio-selected-icon-color: #b428be;
      --mdc-radio-selected-pressed-icon-color: #b428be;
      --mat-mdc-radio-checked-ripple-color: #b428be;
      --mat-mdc-radio-ripple-color: #240826;
      --mdc-radio-disabled-selected-icon-color: #240826;
      --mdc-radio-disabled-unselected-icon-color: #240826;
      --mdc-radio-unselected-focus-icon-color: #212121;
      --mdc-radio-unselected-hover-icon-color: #212121;
      --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
      --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
    }
    &.mat-dark-blue {
      --mdc-radio-selected-focus-icon-color: #00344f;
      --mdc-radio-selected-hover-icon-color: #00344f;
      --mdc-radio-selected-icon-color: #00344f;
      --mdc-radio-selected-pressed-icon-color: #00344f;
      --mat-mdc-radio-checked-ripple-color: #00344f;
      --mat-mdc-radio-ripple-color: #000;
      --mdc-radio-disabled-selected-icon-color: #000;
      --mdc-radio-disabled-unselected-icon-color: #000;
      --mdc-radio-unselected-focus-icon-color: #212121;
      --mdc-radio-unselected-hover-icon-color: #212121;
      --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
      --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
    }

    label {
      letter-spacing: 0;
      @include media-breakpoint('tablet-l') {
        letter-spacing: -0.5px;
      }
    }
  }

  //Slider
  .mat-mdc-slider {
    --mdc-slider-label-label-text-font: Inter, sans-serif;
    --mdc-slider-label-label-text-size: inherit;
    --mdc-slider-label-label-text-line-height: inherit;
    --mdc-slider-label-label-text-tracking: normal;
    --mdc-slider-label-label-text-weight: inherit;
    &.mat-primary,
    &.mat-purple {
      --mdc-slider-handle-color: #b428be;
      --mdc-slider-focus-handle-color: #b428be;
      --mdc-slider-hover-handle-color: #b428be;
      --mdc-slider-active-track-color: #48104c;
      --mdc-slider-inactive-track-color: #c353cb;
      --mdc-slider-with-tick-marks-active-container-color: #48104c;
      --mdc-slider-with-tick-marks-inactive-container-color: #c353cb;
      --mat-mdc-slider-ripple-color: transparent;
      --mat-mdc-slider-hover-ripple-color: transparent;
      --mat-mdc-slider-focus-ripple-color: transparent;
    }
  }

  //Slide toggle
  .mat-mdc-slide-toggle {
    .mdc-switch {
      & .mdc-switch__ripple {
        height: 36px !important;
        width: 36px !important;
      }
    }
    &.mat-primary,
    &.mat-purple {
      --mdc-switch-selected-focus-state-layer-color: #b428be;
      --mdc-switch-selected-handle-color: #b428be;
      --mdc-switch-selected-hover-state-layer-color: #b428be;
      --mdc-switch-selected-pressed-state-layer-color: #b428be;
      --mdc-switch-selected-focus-handle-color: #b428be;
      --mdc-switch-selected-hover-handle-color: #b428be;
      --mdc-switch-selected-pressed-handle-color: #b428be;
      --mdc-switch-selected-focus-track-color: #e9bfec;
      --mdc-switch-selected-hover-track-color: #e9bfec;
      --mdc-switch-selected-pressed-track-color: #e9bfec;
      --mdc-switch-selected-track-color: #e9bfec;
    }
    &.mat-dark-blue {
      --mdc-switch-selected-focus-state-layer-color: #00344f;
      --mdc-switch-selected-handle-color: #00344f;
      --mdc-switch-selected-hover-state-layer-color: #00344f;
      --mdc-switch-selected-pressed-state-layer-color: #00344f;
      --mdc-switch-selected-focus-handle-color: #00344f;
      --mdc-switch-selected-hover-handle-color: #00344f;
      --mdc-switch-selected-pressed-handle-color: #00344f;
      --mdc-switch-selected-focus-track-color: #cbe6ff;
      --mdc-switch-selected-hover-track-color: #cbe6ff;
      --mdc-switch-selected-pressed-track-color: #cbe6ff;
      --mdc-switch-selected-track-color: #cbe6ff;
    }
  }

  .mdc-text-field--disabled .mdc-notched-outline__leading,
  .mdc-text-field--disabled .mdc-notched-outline__notch,
  .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: rgba(0, 0, 0, 0.18);
  }

  //Generic styles for all forms
  & [class*='__container-row'] {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  & [class*='__container-col'] {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }
  & [class*='__group-container-button'] {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    width: 100%;
  }
  & [class*='__wrapper-button'] {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-end;
  }
  & [class*='__form-field-wrapper'] {
    display: flex;
    flex-flow: column wrap;
    align-items: baseline;
    width: 100%;
    margin-bottom: 20px;
  }
  & [class*='__form-field-label'] {
    display: inline-block;
    color: $color-dark-grey-1;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    margin-bottom: 10px;
  }
  & [class*='__form-field-radio-group'] {
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
  }
  & [class*='__form-field-checkbox-group'] {
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
    width: 100%;
    margin-bottom: 20px;
  }
  & [class*='__form-field-slide-toggle'] {
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
  }
  & .w-auto {
    width: auto;
  }
  & .w-20 {
    width: 20%;
  }
  & .w-25 {
    width: 25%;
  }
  & .w-30 {
    width: 30%;
  }
  & .w-50 {
    width: 50%;
  }
  & .w-56 {
    width: 56%;
  }
  & .w-60 {
    width: 60%;
  }
  & .w-75 {
    width: 75%;
  }
  & .w-100 {
    width: 100%;
  }
}
