@include media-breakpoint('desktop') {
  body,
  .main-layout__content,
  .variable-binding {
    :root {
      scroll-behavior: smooth;
    }
    ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      background-color: rgb(76 14 87 / 50%);
      border-radius: 10px;
      box-sizing: content-box;
    }
    ::-webkit-scrollbar:hover {
      background-color: rgb(108 24 114 / 50%);
    }
    ::-webkit-scrollbar-thumb {
      border: 2px solid transparent;
      box-shadow: inset 0 0 0 10px rgb(108 24 114 / 100%);
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover,
    ::-webkit-scrollbar-thumb:active {
      border: 1px solid transparent;
      box-shadow: inset 0 0 0 10px rgb(76 14 87 / 100%);
      border-radius: 10px;
    }
  }
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
