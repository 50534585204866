//Putas modales

.cdk-overlay-pane {
  &.background-grey {
    background-color: $color-light-grey-3;
    border-radius: 5px;
    overflow: hidden;
  }

  &.device-modal-picture,
  &.modal-img,
  &.modal-img-cropper,
  &.background-white {
    background-color: $color-white;
    border-radius: 5px;
    overflow: hidden;
  }
}
.cdk-overlay-backdrop {
  &.backdropBackground {
    background-color: $color-black-50;
  }
}

.modal__title {
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: $color-black;
  padding: 20px;
  margin: 0;
  letter-spacing: -0.1px;
  text-transform: uppercase;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &-icon {
    margin-right: 10px;
  }

  @include media-breakpoint('mobile') {
    font-size: 16px;
    line-height: 20px;
  }
}

.modal__subtitle {
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: $color-black;
  padding: 0 20px;
  margin: 0;
  letter-spacing: -0.1px;
  text-transform: uppercase;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &-icon {
    margin-right: 10px;
  }

  @include media-breakpoint('mobile') {
    font-size: 16px;
    line-height: 20px;
  }
}

.mat-mdc-dialog-container {
  background-color: inherit !important;

  & .mdc-dialog__container {
    background-color: inherit !important;

    & .mdc-dialog__surface {
      background-color: inherit !important;
    }
  }

  &.mdc-dialog {
    & .mdc-dialog__content {
      padding: 30px 50px 10px;
      @include media-breakpoint('mobile') {
        height: auto;
        max-height: 65vh;
        padding: 20px;
        &.app-modal-message__body {
          max-height: 78vh;
        }
      }

      &.add-config-and-readme__main {
        //padding: 0;
      }
    }

    & .mdc-dialog__actions {
      padding: 30px 50px;
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      background-color: inherit;
      position: sticky;
      bottom: 0;
      right: 0;
      width: 100%;
      z-index: 10;
      &.app-modal-message__footer {
        background-color: $color-light-grey-3;
        @include media-breakpoint('mobile') {
          padding: 20px 20px 30px;
        }
      }
      &.mat-mdc-dialog-actions-align-center {
        justify-content: center;
      }

      &.mat-mdc-dialog-actions-align-end {
        justify-content: flex-end;
        @include media-breakpoint('mobile') {
          justify-content: center;
        }
      }

      & .mdc-button--unelevated {
        margin: 0;
        @include media-breakpoint('mobile') {
        }
      }

      @include media-breakpoint('mobile') {
        padding: 0 20px 40px;
        justify-content: center;
        & .mdc-button--unelevated {
          width: 100%;
          margin: 20px 0 0;

          & + .mdc-button--unelevated {
            margin: 20px 0 0;
          }
        }
        & .mdc-button--unelevated {
          &.small {
            margin: 20px 0 0 20px;

            &:first-child {
              margin: 20px 0 0;
            }

            & .material-icons {
              margin: 0;
              font-size: 28px;
              line-height: 28px;
              width: 28px;
              height: 28px;
            }

            & + .mdc-button--unelevated {
              &.small {
                margin: 20px 0 0 20px;

                & .material-icons {
                  margin: 0;
                  font-size: 28px;
                  line-height: 28px;
                  width: 28px;
                  height: 28px;
                }
              }
            }
          }
        }
      }
    }
  }
}
