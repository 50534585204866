//market
$color-transparent: transparent;

$color-white: #ffffff;
$color-white-08: rgb(255, 255, 255, 8%);
$color-white-21: rgb(255, 255, 255, 21%);

$color-black: #000000;
$color-black-03: rgb(0, 0, 0, 3%);
$color-black-10: rgb(0, 0, 0, 10%);
$color-black-20: rgb(0, 0, 0, 20%);
$color-black-25: rgb(0, 0, 0, 25%);
$color-black-30: rgb(0, 0, 0, 30%);
$color-black-50: rgb(0, 0, 0, 50%);
$color-black-75: rgb(0, 0, 0, 75%);
$color-black-87: rgb(0, 0, 0, 87%);

$palette-market-05: #f8eaf9;
$palette-market-1: #f0d4f2;
$palette-market-15: #e9bfec;
$palette-market-2: #e1a9e5;
$palette-market-3: #d27ed8;
$palette-market-4: #c353cb;
$palette-market-5: #b428be;
$palette-market-6: #902098;
$palette-market-7: #6c1872;
$palette-market-8: #48104c;
$palette-market-85: #360c39;
$palette-market-9: #240826;
$palette-market-95: #120413;

$color-error: #b3261e;
$color-dark-red: #ba0b1e;
$color-valid: #0aae4c;
$color-alert: #e6bf18;

$color-coral: #fb3449;
$color-coral-hover: #c80418;
$color-barbara: #ff3c46;

$color-light-grey-0: #cccccc;
$color-light-grey-1: #d8d8d8;
$color-light-grey-2: #e0e0e0;
$color-light-grey-3: #f5f5f5;
$color-light-grey-4: #fafafa;
$color-light-grey-5: #f0f0f0;
$color-light-grey-6: #cfcfcf;
$color-light-grey-7: #dadada;
$color-light-grey-8: #fbfbfb;
$color-light-grey-9: #f2f2f2;
$color-light-grey-10: #f7f7f7;

$color-medium-grey-1: #8c8c8c;
$color-medium-grey-2: #938f99;
$color-medium-grey-3: #5f5f5f;

$color-dark-grey: #4e4e4e;
$color-dark-grey-1: #1c1b1f;
$color-dark-grey-2: #625b71;
$color-dark-grey-3: #49454f;
$color-dark-grey-4: #464a53;

$color-grey-30: #4f4f4f;
$color-grey-50: #808080;
$color-footer: #1e2129;

$color-orange: #fb8649;
$color-primary-10: #ff3449;

$color-light-primary: #0b2945;
$color-light-secondary: $palette-market-7;
$color-tag: $color-light-primary;
$color-tag-special: $color-light-secondary;

$color-base-purple: #e8def8;
$color-base-purple-2: #e7e0ec;
$color-base-purple-3: #fffbfe;
$color-purple-47: rgb($color-base-purple, 47%);
$color-purple-2-45: rgb($color-base-purple-2, 45%);
$color-purple: $palette-market-5;
$color-architectures-bg: $palette-market-05;
$color-architectures-text: $palette-market-8;
$color-light-purple: #f4eff4;
$color-light-purple-1: #e6e1e5;
$color-dark-blue: #001e30;
$color-dark-blue-0: #21005d;
$color-dark-blue-1: #1d192b;
$color-dark-blue-2: #00344f;

$color-light-blue: #cbe6ff;

$color-button-disabled: rgb($color-dark-grey-1, 38%);
$color-bg-button-disabled: rgb(31, 31, 31, 12%);
$color-bg-button-disabled-2: $color-light-grey-6;

$color-purple-50: rgb($palette-market-8, 50%);
