// shadows (elevations)

@mixin elevation-menu-sidebar {
  box-shadow: 0 4px 8px -2px rgb(9, 30, 66, 25%), 0 0 1px rgb(9, 30, 66, 31%) !important;
}

@mixin elevation-menu {
  box-shadow: 4px 4px 4px 0 rgb(0, 0, 0, 25%);
}

@mixin elevation-option {
  box-shadow: 1px 1px 4px 0 rgb(0, 0, 0, 25%);
}

@mixin elevation-option-active {
  box-shadow: 4px 4px 4px 0 rgb(0, 0, 0, 25%);
}

@mixin elevation-menu-mobile {
  box-shadow: 0 4px 4px 0 rgb(0, 0, 0, 25%);
}

@mixin elevation-tags {
  //box-shadow: 1px 1px 4px 1px rgb(210 126 216 / 50%);
  //box-shadow: 1px 1px 4px 1px rgb(255 255 255 / 50%);
  box-shadow: 1px 1px 4px 1px rgb(240 212 242 / 50%);
}

@mixin elevation-light-1 {
  box-shadow: 0px 1px 2px rgb(0, 0, 0, 30%), 0px 1px 3px 1px rgb(0, 0, 0, 15%);
}

@mixin elevation-light-2 {
  box-shadow: 0px 1px 2px rgb(0, 0, 0, 12%), 0px 2px 6px 2px rgb(0, 0, 0, 10%);
}

@mixin elevation-light-3 {
  box-shadow: 0px 4px 8px 3px rgb(0, 0, 0, 15%), 0px 1px 3px rgb(0, 0, 0, 30%);
}

@mixin elevation-light-4 {
  box-shadow: 0px 6px 10px 4px rgb(0, 0, 0, 15%), 0px 2px 3px rgb(0, 0, 0, 30%);
}

@mixin elevation-light-5 {
  box-shadow: 0px 6px 10px 4px rgb(0, 0, 0, 15%), 0px 2px 3px rgb(0, 0, 0, 30%);
}
