@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  src: url('../../fonts/inter/inter-v12-100.eot');
  src: local(''), url('../../fonts/inter/inter-v12-100.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/inter/inter-v12-100.woff2') format('woff2'),
    url('../../fonts/inter/inter-v12-100.woff') format('woff'),
    url('../../fonts/inter/inter-v12-100.ttf') format('truetype'),
    url('../../fonts/inter/inter-v12-100.svg#Inter') format('svg');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  src: url('../../fonts/inter/inter-v12-200.eot');
  src: local(''), url('../../fonts/inter/inter-v12-200.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/inter/inter-v12-200.woff2') format('woff2'),
    url('../../fonts/inter/inter-v12-200.woff') format('woff'),
    url('../../fonts/inter/inter-v12-200.ttf') format('truetype'),
    url('../../fonts/inter/inter-v12-200.svg#Inter') format('svg');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url('../../fonts/inter/inter-v12-300.eot');
  src: local(''), url('../../fonts/inter/inter-v12-300.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/inter/inter-v12-300.woff2') format('woff2'),
    url('../../fonts/inter/inter-v12-300.woff') format('woff'),
    url('../../fonts/inter/inter-v12-300.ttf') format('truetype'),
    url('../../fonts/inter/inter-v12-300.svg#Inter') format('svg');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/inter/inter-v12-400.eot');
  src: local(''), url('../../fonts/inter/inter-v12-400.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/inter/inter-v12-400.woff2') format('woff2'),
    url('../../fonts/inter/inter-v12-400.woff') format('woff'),
    url('../../fonts/inter/inter-v12-400.ttf') format('truetype'),
    url('../../fonts/inter/inter-v12-400.svg#Inter') format('svg');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('../../fonts/inter/inter-v12-500.eot');
  src: local(''), url('../../fonts/inter/inter-v12-500.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/inter/inter-v12-500.woff2') format('woff2'),
    url('../../fonts/inter/inter-v12-500.woff') format('woff'),
    url('../../fonts/inter/inter-v12-500.ttf') format('truetype'),
    url('../../fonts/inter/inter-v12-500.svg#Inter') format('svg');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('../../fonts/inter/inter-v12-600.eot');
  src: local(''), url('../../fonts/inter/inter-v12-600.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/inter/inter-v12-600.woff2') format('woff2'),
    url('../../fonts/inter/inter-v12-600.woff') format('woff'),
    url('../../fonts/inter/inter-v12-600.ttf') format('truetype'),
    url('../../fonts/inter/inter-v12-600.svg#Inter') format('svg');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/inter/inter-v12-700.eot');
  src: local(''), url('../../fonts/inter/inter-v12-700.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/inter/inter-v12-700.woff2') format('woff2'),
    url('../../fonts/inter/inter-v12-700.woff') format('woff'),
    url('../../fonts/inter/inter-v12-700.ttf') format('truetype'),
    url('../../fonts/inter/inter-v12-700.svg#Inter') format('svg');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src: url('../../fonts/inter/inter-v12-800.eot');
  src: local(''), url('../../fonts/inter/inter-v12-800.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/inter/inter-v12-800.woff2') format('woff2'),
    url('../../fonts/inter/inter-v12-800.woff') format('woff'),
    url('../../fonts/inter/inter-v12-800.ttf') format('truetype'),
    url('../../fonts/inter/inter-v12-800.svg#Inter') format('svg');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  src: url('../../fonts/inter/inter-v12-900.eot');
  src: local(''), url('../../fonts/inter/inter-v12-900.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/inter/inter-v12-900.woff2') format('woff2'),
    url('../../fonts/inter/inter-v12-900.woff') format('woff'),
    url('../../fonts/inter/inter-v12-900.ttf') format('truetype'),
    url('../../fonts/inter/inter-v12-900.svg#Inter') format('svg');
}

//Material Icons y sus variantes
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/materialicons/v139/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2)
    format('woff2');
}
@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/materialiconsoutlined/v108/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUcel5euIg.woff2)
    format('woff2');
}
@font-face {
  font-family: 'Material Icons Two Tone';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/materialiconstwotone/v111/hESh6WRmNCxEqUmNyh3JDeGxjVVyMg4tHGctNCu0NjbrHg.woff2)
    format('woff2');
}
@font-face {
  font-family: 'Material Icons Round';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/materialiconsround/v107/LDItaoyNOAY6Uewc665JcIzCKsKc_M9flwmPq_HTTw.woff2)
    format('woff2');
}
@font-face {
  font-family: 'Material Icons Sharp';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/materialiconssharp/v108/oPWQ_lt5nv4pWNJpghLP75WiFR4kLh3kvmvRImcycg.woff2)
    format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  z-index: 1;
  position: relative;
}
.material-icons,
.material-icons-outlined {
  font-family: 'Material Icons Outlined';
  line-height: 1;
  width: auto;
  height: auto;
  display: inline-block;
  z-index: 1;
  position: relative;
}
.material-icons-two-tone {
  font-family: 'Material Icons Two Tone';
  z-index: 1;
  position: relative;
}
.material-icons-round {
  font-family: 'Material Icons Round';
  z-index: 1;
  position: relative;
}
.material-icons-sharp {
  font-family: 'Material Icons Sharp';
  z-index: 1;
  position: relative;
}
$family-inter: 'Inter', sans-serif;
$family-icon: 'Material Icons', sans-serif;
$family-outlined: 'Material Icons Outlined', sans-serif;
$family-two-tone: 'Material Icons Two Tone', sans-serif;
$family-round: 'Material Icons Round', sans-serif;
$family-sharp: 'Material Icons Sharp', sans-serif;
