@import '../market-core';
@import 'buttons';
@import 'forms';
@import 'modals';
.marketplace-default {
  font-family: $family-inter;
  & .title-page {
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    color: $color-black;
    margin: 0;
  }
  & .showing {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: $color-grey-30;
    margin: 0;
  }
  & .spinner {
    height: 50vh;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }
  // sidenav
  & .mat-drawer {
    background-color: $color-light-grey-10;
    @include elevation-menu;

    @include media-breakpoint('tablet-l') {
      background-color: $color-white;
      backdrop-filter: initial;
    }
    @include media-breakpoint('tablet-p') {
      width: auto;
    }
    @include media-breakpoint('mobile') {
      width: 100%;
    }
  }

  // overlay
  & .mat-drawer-backdrop.mat-drawer-shown {
    background-color: $color-black-03;

    @include media-breakpoint('tablet-l') {
      background-color: $color-black-30;
    }
  }

  //Tooltips
  &.mat-mdc-tooltip-show {
    &.custom-tooltip {
      background-color: $color-transparent;
      color: $color-transparent;
      animation: mat-mdc-tooltip-show 150ms cubic-bezier(0, 0, 0.2, 1) forwards;
      animation-delay: 1s;
      & .mdc-tooltip__surface {
        background-color: $color-black-75;
        font-family: $family-inter;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: $color-white;
        padding: 5px 10px;
        width: auto !important;
        max-width: 50vw !important;
        height: auto !important;
        overflow: auto;
        white-space: pre-wrap;
        margin: 0;
        @include media-breakpoint('mobile') {
          font-size: 12px;
          line-height: 16px;
          max-width: 300px !important;
        }
      }
    }
  }
  &.mat-mdc-tooltip-hide {
    &.custom-tooltip {
      display: none;
      background-color: $color-transparent;
      color: $color-transparent;
      animation: mat-mdc-tooltip-hide 75ms cubic-bezier(0.4, 0, 1, 1) forwards;
      & .mdc-tooltip__surface {
        background-color: $color-transparent;
        font-family: $family-inter;
        font-weight: 500;
        font-size: 1px;
        line-height: 0;
        color: $color-transparent;
        padding: 0;
        width: 0;
        max-width: 0;
        height: 0;
        white-space: initial;
        margin: 0;
      }
    }
  }

  // Spinner
  & .mat-mdc-progress-spinner {
    &.mat-purple {
      & .mdc-circular-progress {
        &__determinate-circle,
        &__indeterminate-circle-graphic {
          stroke: $color-purple;
        }
      }
    }
  }
  //Tabs
  & .mat-mdc-tab-header {
    & .mat-mdc-tab-header-pagination-before {
    }
    & .mat-mdc-tab-label-container {
      flex-grow: unset;
    }
    & .mat-mdc-tab {
      &:hover {
        background-color: $color-transparent;
      }
      &.mdc-tab {
        height: auto;
        min-width: initial;
        padding: 0;

        & .mdc-tab__content {
          align-items: flex-end;
          padding: 0;
          margin-right: 20px;
          &:hover {
            background-color: $color-transparent;
          }
          & .mdc-tab__text-label {
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.25px;
            color: $color-dark-grey-3;
            padding: 6px 0 1px;
            border-bottom: 3px solid transparent;
            &:hover {
              background-color: $color-transparent;
            }
          }
        }
        & .mdc-tab-indicator,
        & .mat-mdc-tab-ripple,
        & .mdc-tab__ripple {
          display: none;
        }
        &.mdc-tab--active {
          & .mdc-tab__text-label {
            color: $color-dark-grey-3;
            border-bottom: 3px solid $palette-market-7;
          }
        }
      }
    }
  }
  & .mat-mdc-tab-body-wrapper {
    padding-top: 16px;
    //border-top: 1px solid $color-grey-50;
  }
}
